import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Popover,
  Switch,
  Card,
  CircularProgress,
} from '@mui/material';
import styled from '@emotion/styled';
import { AddCircleOutline } from '@mui/icons-material';
import SettingIcon from '../../../assets/icons/setting-grey-icon.svg';
import WaveIcon0 from '../../../assets/icons/wave-icon-0.svg';
import WaveIcon1 from '../../../assets/icons/wave-icon-1.svg';
import WaveIcon2 from '../../../assets/icons/wave-icon-2.svg';
import WaveIcon3 from '../../../assets/icons/wave-icon-3.svg';
import WaveIcon4 from '../../../assets/icons/wave-icon-4.svg';
import PinIcon from '../../../assets/icons/pin-icon.svg';
import DataEmpty from '../../../assets/img/data_empty.svg';
import TakeCamera from '../../../assets/icons/take-camera.svg';
import TakeCameraActive from '../../../assets/icons/take-camera-active.svg';
import OnButton from '../../../assets/icons/on-button.svg';
import OffButton from '../../../assets/icons/off-button.svg';
import tempGW from '../../../assets/icons/temp-gw.svg';
import humGW from '../../../assets/icons/hum-gw.svg';
import { LocationType } from '../../../state/modules/location/locationReducer';
import {
  useLazyGetControlLocationGatewayNodesQuery,
  ControlLocationGatewayType,
  ControlLocationNodeType,
  useLazyGetControlLocationLogsQuery,
  ControlLocationLogType,
  useHandleAlertControlMutation,
  useGetControlLocationEventImageQuery,
  useRemoveGatewayMutation,
  useRemoveEventImageMutation,
  useUpdateGatewayControlMutation,
  useUpdateNodeATMControlMutation,
} from '../../../services/control.service';
import { useAuth } from '../../../hooks/useAuth';
import { AddNodeDialog } from './dialogs/AddNodeDialog';
import { NodeCard, ZoneCard } from '../../../common/card/NodeCard';
import { UpdateGatewayInfoDialog } from './dialogs/UpdateGatewayInfoDialog';
import { NodeInfoDialog } from './dialogs/NodeInfoDialog';
import dayjs from 'dayjs';
import { INodeType, useGetNodeTypesQuery } from '../../../services/node.service';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { LocationImages } from './LocationImages';
import useModalConfirm from 'hooks/useModalConfirm';
import { sensorMapped } from 'utils/sensorMapping';
import { Switch as SwitchNode } from 'common/Switch/Switch';

const InfoTitle = styled(Typography)({ fontSize: '14px', color: '#8B8C9B' });
const InfoValue = styled(Typography)({ fontSize: '14px', color: '#1E2323' });

const waveList = [WaveIcon0, WaveIcon1, WaveIcon2, WaveIcon3, WaveIcon4];

export const GatewayControl = ({
  location,
  gateway,
  gatewayTypeCode,
  gatewayTypeName,
  refetchGateway,
}: {
  location: LocationType;
  gateway: ControlLocationGatewayType;
  gatewayTypeCode: string;
  gatewayTypeName: string;
  refetchGateway: () => void;
}) => {
  return (
    <Box px={1}>
      <Box>
        <Grid container spacing={1}>
          {gatewayTypeCode !== 'GW-CTL4G' && (
            <Grid item xs={8} style={{ borderRight: '2px solid #EEF2FA', paddingRight: '16px' }}>
              {gatewayTypeCode === 'GW-ATM4G' ? (
                <ATMNode gateway={gateway} location={location} refetchGateway={refetchGateway} />
              ) : (
                <GatewayNodes
                  gatewayTypeCode={gatewayTypeCode}
                  location={location}
                  gateway={gateway}
                  refetchGateway={refetchGateway}
                />
              )}
            </Grid>
          )}

          <Grid item xs={gatewayTypeCode === 'GW-CTL4G' ? 12 : 4}>
            <GatewayInfo
              location={location}
              gateway={gateway}
              gatewayTypeName={gatewayTypeName}
              gatewayTypeCode={gatewayTypeCode}
              refetchGateway={refetchGateway}
            />
          </Grid>

          <Grid item xs={12} style={{ borderTop: '2px solid #EEF2FA' }}>
            <LocationLogs location={location} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const GatewayInfo = ({
  gateway,
  gatewayTypeName,
  gatewayTypeCode,
  location,
  refetchGateway,
}: {
  gateway: ControlLocationGatewayType;
  gatewayTypeName: string;
  gatewayTypeCode: string;
  location: LocationType;
  refetchGateway: () => void;
}) => {
  const [gwSettingAnchorEl, setGwSettingAnchorEl] = useState<any>();
  const [openUpdateGatewayDialog, setOpenUpdateGatewayDialog] = useState(false);
  const [handleAlert] = useHandleAlertControlMutation();
  const [deleteGateway] = useRemoveGatewayMutation();
  const [updateGatewayControl] = useUpdateGatewayControlMutation();
  const { showModalConfirm, hideModalConfirm } = useModalConfirm();
  const { setSnackbar } = useSnackbar();
  const {
    auth: { currentUser, currentAgency },
  } = useAuth();
  const currentDate = dayjs();
  const diffDay = dayjs(gateway.subscription_end_at).diff(dayjs(currentDate), 'day');
  const onClickGwSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    setGwSettingAnchorEl(event.currentTarget);
  };
  const hum = gateway.state?.hum;
  const temp = gateway.state?.temp;
  let humGwValue, tempGwValue;
  if (hum !== undefined) humGwValue = hum / 100;
  if (temp !== undefined) tempGwValue = temp / 100;
  const handleProcessed = () => {
    if (currentUser && location) {
      handleAlert({ agencyId: currentUser.sub_id, locationId: location.id })
        .then((res) => {
          refetchGateway();
          setSnackbar({ open: true, message: 'Xử lý cảnh báo thành công', severity: 'success' });
        })
        .catch(() => setSnackbar({ open: true, message: 'Có lỗi khi xử lý cảnh báo', severity: 'error' }));
    }
  };

  const handleDeleteGateway = () => {
    if (currentUser && location) {
      showModalConfirm({
        type: 'warning',
        title: 'Xoá gateway khỏi vị trí triển khai',
        content: 'Bạn có chắc chắn muốn xoá gateway khỏi vị trí này không?',
        confirm: {
          action: () => {
            deleteGateway({ locationId: location.id, agencyId: currentUser.sub_id, gatewayId: gateway.id })
              .then((res: any) => {
                if (res.error) {
                  setSnackbar({
                    open: true,
                    message: 'Có lỗi khi xoá gateway khỏi vị trí triển khai',
                    severity: 'error',
                  });
                  return;
                }
                setSnackbar({
                  open: true,
                  message: 'Xoá gateway khỏi vị trí triển khai thành công',
                  severity: 'success',
                });
                refetchGateway();
              })
              .catch(() =>
                setSnackbar({ open: true, message: 'Có lỗi khi xoá gateway khỏi vị trítriển khai ', severity: 'error' })
              );

            hideModalConfirm();
          },
          text: 'Xoá gateway',
        },
        cancel: {
          action: hideModalConfirm,
        },
      });
    }
  };

  const handleEnableCallSms = (disable_sms_call: boolean) => {
    showModalConfirm({
      type: 'warning',
      title: `${disable_sms_call ? 'Bật' : 'Tắt'} tổng đài thông báo`,
      content: `Bạn có chắc chắn muốn ${disable_sms_call ? 'Bật' : 'Tắt'} tổng đài không?`,
      confirm: {
        text: 'Đã hiểu',
        action: async () => {
          if (currentUser && location && gateway && currentAgency?.level !== 1) {
            await updateGatewayControl({
              agencyId: currentUser.sub_id,
              locationId: location.id,
              gatewayId: gateway.id,
              data: { disableSmsCall: !disable_sms_call },
            }).then((res: any) => {
              if (res?.data?.success === true) {
                hideModalConfirm();
                setSnackbar({ open: true, message: 'Update tổng đài thành công!', severity: 'success' });
                refetchGateway();
              } else {
                setSnackbar({ open: true, message: 'Có lỗi xảy ra!', severity: 'error' });
              }
            });
          }
        },
      },
      cancel: {
        action: hideModalConfirm,
      },
    });
  };

  return (
    <Box p={1}>
      <Box
        pb={1}
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ borderBottom: '1px solid #EEF2FA' }}
      >
        <Typography style={{ fontSize: '18px', fontWeight: 700 }}>Thông tin Gateway</Typography>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="flex-end">
            <Typography style={{ color: '#8B8C9B' }}>{gateway.state?.battery || 0}%</Typography>
            <img src={PinIcon} alt="" style={{ width: '24px', height: '24px', marginLeft: '4px' }} />
          </Box>
          <Box ml={1} display="flex" alignItems="flex-end">
            <img src={waveList[gateway.state?.gsmLevel || 0]} alt="" style={{ width: '24px', height: '24px' }} />
          </Box>

          <Box ml={1} display="flex" alignItems="flex-end">
            <Tooltip title="Cài đặt gateway">
              <IconButton aria-label="refresh" onClick={onClickGwSetting}>
                <img src={SettingIcon} alt="" style={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Tooltip>
            <Popover
              open={!!gwSettingAnchorEl}
              anchorEl={gwSettingAnchorEl}
              onClose={() => setGwSettingAnchorEl(undefined)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box py={2}>
                <Typography style={{ fontSize: '16px', fontWeight: 'bold', padding: ' 0 16px 16px' }}>
                  Cài đặt Gateway
                </Typography>
                <Box
                  p={2}
                  style={{ fontSize: '14px', cursor: 'pointer', backgroundColor: '#F8F9FC', fontWeight: 500 }}
                  onClick={() => {
                    setOpenUpdateGatewayDialog(true);
                    setGwSettingAnchorEl(undefined);
                  }}
                >
                  Chỉnh sửa thông tin Gateway
                </Box>
                <Box
                  p={2}
                  style={{
                    color: '#E13153',
                    fontSize: '14px',
                    cursor: 'pointer',
                    backgroundColor: '#F8F9FC',
                    fontWeight: 500,
                  }}
                  onClick={handleDeleteGateway}
                >
                  Xoá bỏ gateway này
                </Box>
              </Box>
            </Popover>
          </Box>
        </Box>
      </Box>
      {gatewayTypeCode !== 'GW-CTL4G' && (
        <Box mt={1} py={1} style={{ borderBottom: '1px solid #EEF2FA' }}>
          <Box mt={2} p={2} display="flex" justifyContent="space-around" alignItems="center">
            {gatewayTypeCode === 'GW-ATM4G' && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ color: '#8B8C9B', fontSize: '12px', fontWeight: 500 }}
              >
                <img src={TakeCamera} alt="" />
                <Typography style={{ marginTop: '16px' }}>Chụp ảnh nhanh</Typography>
              </Box>
            )}
            {gatewayTypeCode === 'GW-HM4G' && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ color: '#8B8C9B', fontSize: '12px', fontWeight: 500 }}
              >
                <img src={tempGW} alt="" />
                <Box display={'flex'} justifyContent={'space-around'} style={{ marginTop: '16px' }} gap={0.5}>
                  <Typography> Nhiệt độ</Typography>
                  <Typography style={{ fontWeight: 500, color: '#53535c' }}>{`${tempGwValue}` || `--`}ºC</Typography>
                </Box>
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{
                color: gateway.state?.mode ? '#08C727' : '#8B8C9B',
                fontSize: '12px',
                fontWeight: 500,
                // cursor: 'pointer',
              }}
              // onClick={handleClickOnOff}
            >
              <img src={gateway.state?.mode ? OnButton : OffButton} alt="" />
              <Typography style={{ marginTop: '16px', fontWeight: 500 }}>
                Cảnh báo {gateway.state?.mode ? 'ON' : 'OFF'}
              </Typography>
            </Box>
            {gatewayTypeCode === 'GW-HM4G' && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ color: '#8B8C9B', fontSize: '12px', fontWeight: 500 }}
              >
                <img src={humGW} alt="" />
                <Box display={'flex'} justifyContent={'space-around'} style={{ marginTop: '16px' }} gap={0.5}>
                  <Typography> Độ ẩm</Typography>
                  <Typography style={{ fontWeight: 500, color: '#53535c' }}>{`${humGwValue}` || `--`}%</Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}

      <Box pt={1}>
        <Grid container>
          <Grid item xs={gatewayTypeCode === 'GW-CTL4G' ? 6 : 12} paddingRight={gatewayTypeCode === 'GW-CTL4G' ? 4 : 0}>
            {gatewayTypeCode === 'GW-CTL4G' && (
              <>
                <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
                  <InfoTitle style={{ fontWeight: 600, color: '#1E2323' }}>Output 01 - ON</InfoTitle>
                  <InfoValue>
                    <Switch checked={true} size="small" readOnly />
                  </InfoValue>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
                  <InfoTitle style={{ fontWeight: 600, color: '#1E2323' }}>Output 02 - ON</InfoTitle>
                  <InfoValue>
                    <Switch checked={true} readOnly size="small" />
                  </InfoValue>
                </Box>
              </>
            )}

            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>Loại:</InfoTitle>
              <InfoValue>{gatewayTypeName || '--'}</InfoValue>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>Serial:</InfoTitle>
              <InfoValue>{gateway.serial || '--'}</InfoValue>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>Phiên bản:</InfoTitle>
              <InfoValue>{gateway.version || '--'}</InfoValue>
            </Box>
            {gatewayTypeCode === 'GW-CTL4G' && (
              <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
                <InfoTitle>Điện áp:</InfoTitle>
                <InfoValue>{gateway?.state?.voltage || '--'} V</InfoValue>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>Số điện thoại:</InfoTitle>
              <InfoValue>{gateway.phone_number || '--'}</InfoValue>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>Ngày kích hoạt:</InfoTitle>
              <InfoValue>{dayjs(gateway.active_at).format('DD/MM/YYYY HH:mm')}</InfoValue>
            </Box>
          </Grid>
          <Grid item xs={gatewayTypeCode === 'GW-CTL4G' ? 6 : 12} paddingLeft={gatewayTypeCode === 'GW-CTL4G' ? 4 : 0}>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>Số ngày còn lại:</InfoTitle>
              <InfoValue>{diffDay} Ngày</InfoValue>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>Trạng thái hoạt động:</InfoTitle>
              <InfoValue>{gateway.state?.connection === 'online' ? 'Đang hoạt động' : 'Offline'}</InfoValue>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>Trạng thái kích hoạt:</InfoTitle>
              <InfoValue>{gateway.status === 'activated' ? 'Đã kích hoạt' : 'Lưu kho'}</InfoValue>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>Cập nhật lần cuối:</InfoTitle>
              <InfoValue>
                {gateway.state ? dayjs(gateway.state.timestamp * 1000).format('DD/MM/YYYY HH:mm') : '--'}
              </InfoValue>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1} sx={{ cursor: 'pointer' }}>
              <InfoTitle>{'Tổng đài cảnh báo'}:</InfoTitle>
              <InfoValue>
                <Switch
                  checked={gateway.disable_sms_call ? false : true}
                  onClick={() => handleEnableCallSms(gateway.disable_sms_call)}
                />
              </InfoValue>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
              <InfoTitle>{'Chế độ Test & Kiểm thử'}:</InfoTitle>
              <InfoValue>
                <Switch size="small" checked={gateway.testing} readOnly />
              </InfoValue>
            </Box>
            {gatewayTypeCode === 'GW-CTL4G' && (
              <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
                <InfoTitle>{'Xử lý cảnh báo'}:</InfoTitle>
                <InfoValue>
                  <Button
                    variant="contained"
                    style={{ padding: '4px 16px', borderRadius: '8px', height: '36px' }}
                    onClick={handleProcessed}
                    disabled={location.state !== 'alert'}
                  >
                    Đã xử lý
                  </Button>
                </InfoValue>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* {gatewayTypeCode === 'GW-ATM4G' && (
        <Box mt={1} py={2} style={{ borderTop: '1px solid #EEF2FA' }}>
          <Typography style={{ fontWeight: 600, fontSize: 18 }}>Thiết bị chống cướp</Typography>
          <Box mt={2} p={2} display="flex" justifyContent="space-around" alignItems="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{ color: '#8B8C9B', fontSize: '12px', fontWeight: 500 }}
            >
              <img src={TakeCamera} alt="" />
              <Typography style={{ marginTop: '16px' }}>Chụp ảnh nhanh</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{ color: '#08C727', fontSize: '12px', fontWeight: 500 }}
            >
              <img src={OnButton} alt="" />
              <Typography style={{ marginTop: '16px' }}>Máy phun khói</Typography>
            </Box>
          </Box>
        </Box>
      )} */}

      {location && gateway && openUpdateGatewayDialog && (
        <UpdateGatewayInfoDialog
          gateway={gateway}
          locationId={location.id}
          open={openUpdateGatewayDialog}
          onClose={() => setOpenUpdateGatewayDialog(false)}
          onSuccess={refetchGateway}
        />
      )}
    </Box>
  );
};

const LocationLogs = ({ location }: { location: LocationType }) => {
  const [getControlLocationLogs, { data: logsData }] = useLazyGetControlLocationLogsQuery();
  const {
    auth: { currentUser },
  } = useAuth();

  useEffect(() => {
    if (currentUser && location) {
      getControlLocationLogs({
        agencyId: currentUser.sub_id,
        locationId: location.id,
      }).unwrap();
    }
  }, [currentUser, location]);

  const logs = (logsData || []) as ControlLocationLogType[];

  return (
    <>
      <Box py={2} mb={2}>
        <Typography style={{ fontSize: '18px', fontWeight: 700 }}>Nhật ký hoạt động</Typography>
      </Box>
      {logs.length > 0 ? (
        <Box>
          <Grid
            container
            style={{
              borderTop: '1px solid #F6F9FC',
              borderBottom: '1px solid #F6F9FC',
              height: '48px',
              fontWeight: 500,
              fontSize: '14px',
            }}
          >
            <Grid item xs={2}>
              <Box
                style={{
                  color: '#8B8C9B',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                Thời gian
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                style={{
                  color: '#8B8C9B',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                Loại nhật ký
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box
                style={{
                  color: '#8B8C9B',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                Chi tiết nhật ký
              </Box>
            </Grid>
          </Grid>

          <Grid container style={{ fontSize: '14px', maxHeight: '294px', overflow: 'auto' }}>
            {logs.map((item, index) => (
              <>
                <Grid
                  item
                  xs={2}
                  height="40px"
                  style={{
                    borderBottom: '1px solid #F8F9FC',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    {dayjs(item.timestamp * 1000).format('HH:mm:ss DD-MM-YYYY')}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  height="40px"
                  style={{
                    borderBottom: '1px solid #F8F9FC',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    {item.kind === 'event' ? 'Cảnh Báo' : 'Thông báo'}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={7}
                  height="40px"
                  style={{
                    borderBottom: '1px solid #F8F9FC',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    {item.message}
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" minHeight="180px">
          <Box>
            <img src={DataEmpty} alt="" style={{ width: '120px' }} />
            <Typography style={{ margin: '20px', color: '#C5C6D2' }}>Danh sách trống</Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

const GatewayNodes = ({
  location,
  gateway,
  gatewayTypeCode,
  refetchGateway,
}: {
  location: LocationType;
  gateway: ControlLocationGatewayType;
  gatewayTypeCode: string;
  refetchGateway: () => void;
}) => {
  const [openAddNodeDialog, setOpenAddNodeDialog] = useState(false);
  const [selectedNode, setSelectedNode] = useState<ControlLocationNodeType>();
  const [getControlLocationGatewayNodes, { data }] = useLazyGetControlLocationGatewayNodesQuery();
  const { data: nodeTypes } = useGetNodeTypesQuery<{ data: INodeType[] }>(null);
  const [handleAlert] = useHandleAlertControlMutation();
  const { setSnackbar } = useSnackbar();
  const {
    auth: { currentUser },
  } = useAuth();

  useEffect(() => {
    if (currentUser && location && gateway) {
      getControlLocationGatewayNodesFetch();
    }
  }, [currentUser, location, gateway]);

  const getControlLocationGatewayNodesFetch = async () => {
    if (currentUser && location && gateway) {
      await getControlLocationGatewayNodes(
        {
          agencyId: currentUser.sub_id,
          locationId: location.id,
          gatewayId: gateway.id,
        },
        false
      ).unwrap();
    }
  };

  const handleProcessed = () => {
    if (currentUser && location) {
      handleAlert({ agencyId: currentUser.sub_id, locationId: location.id })
        .then((res) => {
          refetchGateway();
          setSnackbar({ open: true, message: 'Xử lý cảnh báo thành công', severity: 'success' });
        })
        .catch(() => setSnackbar({ open: true, message: 'Có lỗi khi xử lý cảnh báo', severity: 'error' }));
    }
  };

  const nodeData = (data || []) as ControlLocationNodeType[];
  const nodeGWC4GType = nodeTypes?.find((item) => item.code === 'SN-ZONE');

  const nodes =
    gatewayTypeCode === 'GW-C4G' && nodeGWC4GType
      ? nodeData.filter((item) => item.node_type_id !== nodeGWC4GType.id)
      : nodeData;
  const zones =
    gatewayTypeCode === 'GW-C4G' && nodeGWC4GType
      ? nodeData.filter((item) => item.node_type_id === nodeGWC4GType.id)
      : [];

  const nodeConnection = nodes?.filter((item: any) => {
    return item.state.connection === 'online';
  });
  const nodeDisconnection = nodes?.filter((item: any) => {
    return item.state.connection === 'offline';
  });
  return (
    <>
      {gatewayTypeCode === 'GW-C4G' && (
        <Box>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <Typography style={{ fontSize: '18px', fontWeight: 700 }}>Danh sách Zone</Typography>
            </Box>
            {nodes.length < 1 && zones.length > 0 && (
              <Box mt={1}>
                <Button
                  variant="contained"
                  style={{ borderRadius: '8px', height: '36px' }}
                  onClick={handleProcessed}
                  disabled={location.state !== 'alert'}
                >
                  Đã xử lý
                </Button>
              </Box>
            )}
          </Box>
          <Box mt={3} mb={2} maxHeight={'440px'} style={{ overflow: 'auto' }}>
            <Grid container spacing={2}>
              {zones.map((item) => {
                return (
                  <Grid key={'zone' + item.id} item md={6}>
                    <ZoneCard
                      item={item}
                      onClickCard={() => {
                        setSelectedNode(item);
                      }}
                      locationId={location.id}
                      gatewayId={gateway.id}
                      onRefreshZone={getControlLocationGatewayNodesFetch}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      )}

      {nodes.length > 0 ? (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box flex={1}>
              <Box display="flex" justifyContent="flex-start" alignItems="center" paddingRight={5}>
                <Typography style={{ fontSize: '18px', fontWeight: 700 }}>Danh sách Node</Typography>
                <Box display="flex" justifyContent="center" alignItems="center" pl={2} gap={2}>
                  <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                    <img src={sensorMapped.power_on_dot} alt="" />
                    <Typography style={{ fontSize: '16px', fontWeight: 400 }}>{nodeConnection.length}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                    <img src={sensorMapped.power_off_dot} alt="" />
                    <Typography style={{ fontSize: '16px', fontWeight: 400 }}>{nodeDisconnection.length}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box mt={1} mr={2}>
              <Button
                variant="contained"
                style={{ padding: '4px 16px', borderRadius: '8px', height: '36px' }}
                onClick={handleProcessed}
                disabled={location.state !== 'alert'}
              >
                Đã xử lý
              </Button>
            </Box>
            <Box mt={1}>
              <Button
                variant="outlined"
                style={{ padding: '4px 12px', borderRadius: '8px', height: '36px' }}
                startIcon={<AddCircleOutline />}
                onClick={() => setOpenAddNodeDialog(true)}
              >
                Thêm Node
              </Button>
            </Box>
          </Box>
          <Box mt={3} mb={2} maxHeight={'440px'} style={{ overflow: 'auto' }}>
            <Grid container spacing={2}>
              {nodes.map((item) => {
                return (
                  <Grid key={item.id} item md={4} lg={3}>
                    <NodeCard
                      data={item}
                      nodeTypes={nodeTypes || []}
                      onClickCard={() => {
                        setSelectedNode(item);
                      }}
                      refetchGateway={refetchGateway}
                      gatewayMode={gateway.state?.mode || 0}
                      gatewayConnection={gateway.state?.connection || ''}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box flex={1}>
              <Typography style={{ fontSize: '18px', fontWeight: 700 }}>Danh sách Node</Typography>
            </Box>
            <Box mt={1}>
              <Button
                variant="outlined"
                style={{ padding: '4px 12px', borderRadius: '8px', height: '36px' }}
                startIcon={<AddCircleOutline />}
                onClick={() => setOpenAddNodeDialog(true)}
              >
                Thêm Node
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {location && gateway && openAddNodeDialog && (
        <AddNodeDialog
          locationId={location.id}
          gatewayId={gateway.id}
          gatewayStatus={gateway.state?.connection!}
          open={openAddNodeDialog}
          onClose={() => setOpenAddNodeDialog(false)}
          onSuccess={getControlLocationGatewayNodesFetch}
        />
      )}
      {location && gateway && selectedNode && (
        <NodeInfoDialog
          gatewayId={gateway.id}
          locationId={location.id}
          node={selectedNode}
          open={!!selectedNode}
          onClose={() => setSelectedNode(undefined)}
          onSuccess={getControlLocationGatewayNodesFetch}
        />
      )}
    </>
  );
};

const ATMNode = ({
  location,
  gateway,
  refetchGateway,
}: {
  location: LocationType;
  gateway: ControlLocationGatewayType;
  refetchGateway: () => void;
}) => {
  const { setSnackbar } = useSnackbar();
  const [removeEventImage] = useRemoveEventImageMutation();
  const { showModalConfirm, hideModalConfirm } = useModalConfirm();
  const [updateNodeATMControl] = useUpdateNodeATMControlMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCamera, setIsLoadingCamera] = useState<boolean>(false);

  const {
    auth: { currentUser },
  } = useAuth();

  const { data: imagesData, refetch: refetchImages } = useGetControlLocationEventImageQuery(
    {
      locationId: location.id,
      agencyId: currentUser?.sub_id || '',
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: nodeTypes } = useGetNodeTypesQuery<{ data: INodeType[] }>(null);
  const [handleAlert] = useHandleAlertControlMutation();

  const LoadingCard = styled(Card)({
    cursor: 'pointer',
    backgroundColor: '#d1d1d17c',
    boxShadow: 'none',
    borderRadius: '8px',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const DLNodeCard = styled(Card)({
    cursor: 'pointer',
    backgroundColor: '#F6F9FC',
    boxShadow: 'none',
    border: '1.5px solid #EEF2FA',
    borderRadius: '8px',
    padding: '16px 16px 8px',
    position: 'relative',
  });

  const nodesCode = [
    { code: 'NB-SMOKE', name: 'Cảm biến khói', sensor_type: 'SR-A300' },
    { code: 'NB-DOOR', name: 'Cảm biến cửa', sensor_type: 'SR-A200' },
    { code: 'NB-VIBRATE', name: 'Cảm biến rung', sensor_type: 'SR-A400' },
    { code: 'NB-ARB', name: 'Cảm biến dò điện', sensor_type: 'SR-A500' },
  ];

  const listSensors = gateway.state?.sensors ? gateway.state.sensors : [];

  const nodes = useMemo(() => {
    if (!nodeTypes) return [];
    console.log('nodeTypes: ', nodeTypes);

    return nodesCode.map((item) => {
      const timeStatus = gateway.state?.mode ? gateway.state.timestamp : 0;

      const nodeType = nodeTypes.find((nt) => nt.code === item.code);

      let sensorATM: any = listSensors?.find((sens) => sens.type === item.sensor_type) || [];

      return {
        id: '',
        agency_id: '',
        node_type_id: nodeType?.id || '',
        name: item.name,
        serial: '',
        version: '',
        mfg: '',
        alert: 0,
        state: {
          battery: 0,
          hum: 0,
          nType: '',
          status: sensorATM?.status ? sensorATM.status : 0,
          mode_alert: sensorATM?.mode ? sensorATM.mode : 0,
          power: 0,
          temp: 0,
          timestamp: timeStatus,
          connection: '',
          sensor_type: item.sensor_type || '',
        },
        status: '',
        created_at: '',
        updated_at: '',
      };
    });
  }, [nodeTypes]);

  const sensors = gateway.state?.sensors;
  const tempSensor: any = sensors?.find((item) => item.type === 'SR-A100');
  const cameraSensor: any = sensors?.find((item) => item.type === 'SR-A900');

  const temps = tempSensor?.temp;

  const handleConvertTemp = (temp: any) => {
    if (!temp) return;
    let tempConverted = Number(temp) / 100;
    return tempConverted;
  };

  const handleProcessed = () => {
    if (currentUser && location) {
      handleAlert({ agencyId: currentUser.sub_id, locationId: location.id })
        .then((res) => {
          refetchGateway();
          setSnackbar({ open: true, message: 'Xử lý cảnh báo thành công', severity: 'success' });
        })
        .catch(() => setSnackbar({ open: true, message: 'Có lỗi khi xử lý cảnh báo', severity: 'error' }));
    }
  };

  const onDeleteEventImage = (imgId: string) => {
    if (currentUser && location) {
      removeEventImage({ agencyId: currentUser.sub_id, locationId: location.id, eventId: imgId })
        .then((res: any) => {
          if (res.error) {
            setSnackbar({ open: true, message: 'Có lỗi khi xoá ảnh này', severity: 'error' });
            return;
          }
          hideModalConfirm();
          setSnackbar({ open: true, message: 'Xoá ảnh ản thành công', severity: 'success' });
          refetchImages();
        })
        .catch(() => setSnackbar({ open: true, message: 'Có lỗi khi xoá ảnh', severity: 'error' }));
    }
  };

  const handleControlNodeTempATM = (event: React.ChangeEvent<HTMLInputElement>, tempSensor: any) => {
    if (currentUser && location && gateway.state?.mode === 1 && gateway.state.connection === 'online') {
      setIsLoading(true);
      updateNodeATMControl({
        agencyId: currentUser.sub_id,
        locationId: location.id,
        gatewayId: gateway.id,
        data: {
          sensor_type: tempSensor.type,
          alert_mode: event.target.checked,
        },
      }).then((res: any) => {
        if (res && res.data?.success === true) {
          setTimeout(() => {
            setIsLoading(false);
            refetchGateway();
            setSnackbar({ open: true, message: 'Cập nhật chế độ cảnh báo thành công', severity: 'success' });
          }, 2000);
        }
      });
    }
  };
  const  handleControlNodeCamera = (event: React.ChangeEvent<HTMLInputElement>, cameraSensor: any) => {
    if (currentUser && location && gateway.state?.mode === 1 && gateway.state.connection === 'online') {
      setIsLoadingCamera(true);
      updateNodeATMControl({
        agencyId: currentUser.sub_id,
        locationId: location.id,
        gatewayId: gateway.id,
        data: {
          sensor_type: cameraSensor.type,
          alert_mode: event.target.checked,
        },
      }).then((res: any) => {
        if (res && res.data?.success === true) {
          setTimeout(() => {
            setIsLoading(false);
            refetchGateway();
            setSnackbar({ open: true, message: 'Cập nhật chế độ cảnh báo thành công', severity: 'success' });
          }, 2000);
        }
      });
    }
  };

  return (
    <Box>
      <Box>
        <LocationImages images={imagesData || []} onDeleteEventImage={onDeleteEventImage} />
      </Box>
      <Box mt={6} mb={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box flex={1}>
            <Typography style={{ fontSize: '18px', fontWeight: 700 }}>Danh sách Node</Typography>
          </Box>
          <Box mt={1} mr={2}>
            <Button
              variant="contained"
              style={{ padding: '4px 16px', borderRadius: '8px', height: '36px' }}
              onClick={handleProcessed}
              disabled={location.state !== 'alert'}
            >
              Đã xử lý
            </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>
          {nodes.map((item) => {
            return (
              <Grid key={item.id} item md={4} lg={3}>
                <NodeCard
                  showStatus={true}
                  showPin={false}
                  data={item}
                  nodeTypes={nodeTypes || []}
                  onClickCard={() => {}}
                  gatewayId={gateway.id}
                  locationId={location.id}
                  refetchGateway={refetchGateway}
                  gatewayMode={gateway.state?.mode || 0}
                  gatewayConnection={gateway.state?.connection || ''}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={4} lg={3}>
          <DLNodeCard>
          {isLoadingCamera && (
            <LoadingCard>
              <Box>
                <CircularProgress />
              </Box>
            </LoadingCard>
          )}
            <Box display="flex" justifyContent={'space-between'} alignItems="center" pb={2}>
              <Box>
                {cameraSensor?.status ? (
                  <img src={sensorMapped.power_on_dot} alt="" />
                ) : (
                  <img src={sensorMapped.power_off_dot} alt="" />
                )}
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <SwitchNode
                  disabled={isLoadingCamera}
                  checked={Boolean(cameraSensor?.mode)}
                  onChange={(event) => handleControlNodeCamera(event, cameraSensor)}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box style={{ minHeight: 75, width:'58px' }}>
              {cameraSensor?.status ? (
                   <img src={TakeCameraActive} alt="" style={{width:'100%',height:'100%'}}/>
                ) : (
                  <img src={TakeCamera} alt="" style={{width:'100%',height:'100%'}}/>
                )}
               
              </Box>
              <Box mt={1} minHeight={'48px'}>
                <Typography style={{ fontWeight: 500, lineHeight: '22px', textAlign: 'center' }}>{'Camera'}</Typography>
              </Box>
            </Box>
          </DLNodeCard>
        </Grid>
      </Grid>

      <Box my={2}>
        <Typography style={{ fontWeight: 600, fontSize: 18 }}>Nhiệt độ hiện tại</Typography>
        <Grid container spacing={4} style={{ position: 'relative', top: 0, left: 0 }}>
          {isLoading && (
            <LoadingCard>
              <Box>
                <CircularProgress />
              </Box>
            </LoadingCard>
          )}
          <Grid item xs={12} sm={6}>
            <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box style={{ color: '#8B8C9B' }}>Cảm biến 1</Box>
              <Box style={{ color: '#E13153', fontWeight: 500 }}>{handleConvertTemp(temps?.[0]) || '--'}&#8451;</Box>
            </Box>
            <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box style={{ color: '#8B8C9B' }}>Cảm biến 2</Box>
              <Box style={{ color: '#E13153', fontWeight: 500 }}>{handleConvertTemp(temps?.[1]) || '--'}&#8451;</Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box style={{ color: '#8B8C9B' }}>Cảm biến 3</Box>
              <Box style={{ color: '#E13153', fontWeight: 500 }}>{handleConvertTemp(temps?.[2]) || '--'}&#8451;</Box>
            </Box>
            <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box style={{ color: '#8B8C9B' }}>Cảnh báo nhiệt độ</Box>
              <Box display="flex">
                <Typography style={{ color: '#E13153', fontWeight: 500, marginRight: '16px' }}>65&#8451;</Typography>
                <Switch
                  checked={Boolean(tempSensor?.mode)}
                  size="small"
                  onChange={(event) => handleControlNodeTempATM(event, tempSensor)}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
